import React, { useEffect, useState } from 'react'
import './styles.scss'

const InputHour = ({ set, defaultValue }) => {
  const [hour, setHour] = useState('00')
  const [minutes, setMinutes] = useState('00')
  const [ap, setAp] = useState('am')
const handleClick = (value) =>{
  if(value == 'hours'){
    setHour('')
  }else if(value == 'ap'){
    setAp('')
  }
}
  useEffect(() => {
    if (ap == 'am') {
      set(`${hour}:${minutes}`)
    } else {
      set(`${hour == 12 ? hour : Number(hour) + 12}:${minutes}`)
    }
  }, [hour, minutes, ap])

  useEffect(() => {
    console.log(defaultValue);
    if (defaultValue) {
      const hours = defaultValue.substring(0, 2)
      setHour(hours)
    }
  }, [defaultValue])
useEffect(() => {
  if(ap !== 'am'){
    if(ap !== 'pm'){
      setAp('')
    }
  }
}, [ap])
useEffect(() => {
  const array = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23']
    if(!array.includes(`${hour}`)){
      setHour('')
    }
}, [hour])

  const validate = (string) => {
    var regex = /^\d{2}$/;
    return regex.test(string);
  }

  return (
    <div className='time_container'>
      <article className='time'>
        <div className='timeR'>

        <div className='time_input'>
          <input className='time_hour' onInput={(e) => setHour(e.target.value)}
          onClick={()=> handleClick('hours')}
            value={hour}
            required
            list='hours'
           />
          <div className='line'></div>
          <datalist id='hours'>
            {[...Array(24)].map((_, index) => (
              <option value={validate(index) ? `${index}` : `0${index}`}></option>
            ))}
          </datalist>
        </div>
        <p>:</p>
        <div className='time_input'>
          <input className='time_minutes'
            value={minutes}
            list='minutes' />
        </div>
        </div>
        <div className='time_space'></div>
        <div className='time_input'>
          {/* <input className='time_ap'
          onClick={()=> handleClick('ap')}
          onInput={(e) => setAp(e.target.value)}
            value={ap}
            list='ap'
            required
          /> */}
          <div className='line'></div>
          <datalist id='ap'>
            {[...Array(2)].map((_, index) => (
              <option value={index === 0 ? 'am' : 'pm'}></option>
            ))}
          </datalist>
        </div>
      </article>
    </div>
  )
}

export default InputHour