import { message } from 'antd'
import React from 'react'
import Swal from 'sweetalert2';

// Componente input personalizado para subir archivos


const Files = ({ setvalue, value, typeData, type }) => {
	const handleOnchage = (event) => {
		const file = event.target.files[0]
		if (!typeData.includes(file?.type)) {
			message.error('El tipo de dato no es valido')
		} else {
			if (type == 'logo') {
				const img = new Image();
				img.src = URL.createObjectURL(file);
				img.onload = function () {
					if (img.width == 640 || img.height == 360) {
						setvalue(file)
					}else{
						Swal.fire({
							icon: 'error',
							title:'Tamaño incorrecto',
							text:'Recuerde que el tamaño de la imagen debe ser de 640x360'
						})
					}
				}
			}else{
				setvalue(file)
			}

		}
	}
	
		return (
			<div>
				<input type="file" class="btn btn-outline-primary btn-icon m-1 w-full" onChange={handleOnchage} />
				<br />
				<span className="text-gray-600" >{value?.name}  </span>
			</div >
		)
	}

	export default Files;