import React from "react";
import InputComponent from "../../components/Inputs/InputComponent3/InputComponent3";
import { useForm } from "react-hook-form";
import { modalError } from "../../components/SweetAlert/Error";
import { Loading } from "../../components/Loading/Loading";
import { modalSucces } from "../../components/SweetAlert/Success";
import Api from "../../common/Api/Api";
import { useQuery } from "react-query";
import jwt_decode from "jwt-decode";
import { Token } from "../../common/Storage/Token";

export const Profile = () => {
  var dataForm
  const userData = jwt_decode(Token.getToken());

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();

  const { isLoading, refetch } = useQuery(
    ["recoveryPass", dataForm],
    () =>
      Api.post("/login/UpdatePassword", dataForm),
    {
      onSuccess: data => {
        if (data?.ok === false) {
          modalError({ message: data?.payload.Message ? data?.payload.Message : 'Revisa tus datos, por favor' });
        } else {
          modalSucces({ message: "Por favor revise su correo", url: "/" });
        }
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );


  const onSubmit = (data) => {
    
    if (data.newpass2 == data.newpass) {
      dataForm = {
        iduser: userData?.data?.IdUser,
        pass: data.pass,
        newpass: data.newpass
      }
      refetch()
    } else {
      modalError({ message: 'Las contraseñas no coinciden' })
    }
  }


  return (
    <div className="profile">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col justify-center g-5">
        <h2>Cambiar contraseña</h2>
        <div className="input-component3 flex flex-col items-center g-5">
          <p>Contraseña actual</p>
          <input required {...register("pass", { required: true })} type="text"/>
        </div>
        <div className="input-component3 flex flex-col items-center g-5">
          <p>Nueva Contraseña</p>
          <input required {...register("newpass", { required: true })} type="text"/>
        </div>
        <div className="input-component3 flex flex-col items-center g-5">
          <p>Confirmar Nueva Contraseña</p>
          <input required {...register("newpass2", { required: true })} type="text"/>
        </div>
        
        <button type="submit" className="submit mt-10">Enviar</button>
      </form>
      <Loading visible={isLoading} />
    </div>
  );
};
