import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import InputComponent from '../../../Inputs/InputComponent3/InputComponent3';
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { modalError } from '../../../SweetAlert/Error';
import { modalSucces } from '../../../SweetAlert/Success';
import Api from '../../../../common/Api/Api';
import { Loading } from '../../../Loading/Loading';

export const EditProduct = ({ visible, setVisible, product }) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();
  const [namep, setNamep] = useState('')
  const [precio, setPrecio] = useState('')
  // Activa las peticion de tipo post para editar producto controlando la respuesta de la misma

  const mutation = useMutation(data => {
    return Api.post('/products/EditProduct', data)
  }, {
    onSuccess: data => {
      if (data?.ok === false) {

        modalError({ message: data?.payload.message ? data?.payload.message : 'Revisa tus datos, por favor' });
      } else {
        modalSucces({ message: "El producto fue editado de manera exitosa.", reload: true, title: 'Cambiado' });
      }
    },
    onError: () => {
      modalError({ message: 'Parece que tenemos problemas' });
    }
  })
useEffect(() => {
 setNamep(product?.NameProduct)
 setPrecio(product?.UnitValue)
}, [product])

  //envia los datos del formulario a la petición post

  const onSubmit = (e) => {
    mutation.mutate({
      idproduct: product?.IdProduct,
      name: namep,
      valueunit: precio
    })
  };


  return (
    <Modal title=" producto" visible={visible} okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }} onCancel={() => setVisible(!visible)}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mx-4 justify-center items-center w-full mr-10 " >
        <div className='input-component3 flex flex-col gap-2'>
          <p>Nombre del producto</p>
          <input type="text" required value={namep} onInput={(e) => setNamep(e.target.value)} />
        </div>
        <div className='input-component3 flex flex-col gap-2'>
          <p>Precio</p>
          <input type="number" required value={precio} onInput={(e) => setPrecio(e.target.value)} />
        </div>
        <input type="submit" className="btn-close p-2" value="Aceptar" />
      </form>
      <Loading visible={mutation.isLoading} />
    </Modal>
  )
}
