import React from 'react'

const InputForm = ({ item, register, data, error }) => {
    return (
        <div className="input-component3 m-4 mb-2 undefined">
            <div className="w-11/12">
                <p className="mini-texts  m-0 font-semibold  text-gray-500">{item?.name}</p>
                {data !== "" ? 
               (item.validate ?
                <input {...register(`${item?.register}`, item.validate)} value={data} className={`mb-2 ${error ? 'bordererror': ''}`} /> :
                <input {...register(`${item?.register}`, { required: true })} value={data} className={`mb-2 ${error ? 'bordererror': ''}`} />)
                : 
                item.validate ?
                <input {...register(`${item?.register}`, item.validate)} className={`mb-2 ${error ? 'bordererror': ''}`} /> :
                <input {...register(`${item?.register}`, { required: true })} className={`mb-2 ${error ? 'bordererror': ''}`} />
                }
            </div>
            {error? <span className="text-red-500 text-base">Es necesario ingresar este campo</span>: <></>}
        </div>
    )
}

export default InputForm