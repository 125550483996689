import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { BiMenu } from "react-icons/bi";
import { Sidebar } from "./Sidebar/Sidebar";
import { HeaderC } from "./Header/Header";
import './styles.scss'
// componente layout del dashboard

export const LayoutPrivate = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const [collapse, setCollapse] = useState(false);
  const [visible, setvisible] = useState(false)

  // Accion que oculta o muestra el sidebar
useEffect(() => {
  const resizeCarrusel = () => {
    setWidth(window.innerWidth);
  };
  window.addEventListener("resize", resizeCarrusel);
  return () => {
    window.removeEventListener("resize", resizeCarrusel);
  };
})
useEffect(() => {
if(width> 570){
  setvisible(false)
}else if(width<570){
  setvisible(true)
}
}, [width])

  const handleToggle = () => {
    collapse ? setCollapse(false) : setCollapse(true);
  };

  return (
    <Layout className="private-layout">
      <div onClick={() => setvisible(!visible)} className="menu">
        {visible === true ? <BiMenu /> : <AiOutlineClose />}
      </div>
      <Sidebar visible={visible} setvisible={setvisible} collapsed={collapse} onCollapse={handleToggle} />
      <Layout className=" site-layout">
        <HeaderC />
        {/*   Aquí se ubica el conteido del dashboard por sección */}
        <main >{children} </main>
      </Layout>
    </Layout>
  );
};
