import React, { useState } from "react";
import InputComponent from "../../../components/Inputs/InputComponent2/InputComponent2";
import logo from "../../../assets/img/Logo.svg";
import logoWhite from "../../../assets/img/logoWhite.png";
import { auth } from "../../../services/Auth/AuthActions";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import bg from '../../../assets/img/bg-blue.png'
import { Loading } from '../../../components/Loading/Loading'
import { AiFillEye } from "react-icons/ai";

export const Login = () => {
  const { loading } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false)
  const dispatch = useDispatch();
  const loadingIcon = loading && loading === true ? "Cargando..." : "Entrar"
  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    watch
  } = useForm();

  const onSubmit = (data, e) => {
    
    dispatch(auth.login(data));

  }

  return (
    <div className="login">

      <div className="login_content" style={{ backgroundImage: `url(${bg})` }}>
        <img alt="resp-logo" src={logoWhite} />
        <h1>INICIA SESIÓN</h1>
        <form className="pt-16" onSubmit={handleSubmit(onSubmit)}>
        <div className="input-component2-icon w-11/12 mb-5">
          <p>Usuario</p>
          <div className="input-component2-icon__content">
            <input type="text" {...register('User', {required: true})}/>
          </div>
          {errors.User && (
              <span className="text-red">Usuario requerido</span>
            )}
        </div>

          <div className="input-component2-icon w-11/12 mb-5">
            <p>Contraseña</p>
            <div className="input-component2-icon__content">
              <input type={show ? "text" : "password"} {...register("pass", { required: true })} />
              <AiFillEye className="cursor-pointer" onClick={() => setShow(!show)} />
            </div>
            {errors.pass && (
              <span className="text-red">La contraseña es requerida</span>
            )}
          </div>
          <Link to="/password">¿Olvidaste tu contraseña?</Link>
          <button type="submit" className="submit" >Entrar</button>
          <p>
            ¿Aún no tienes una cuenta?
            <b>
              <br />
              <br />
              <Link to="/request">Solicita un usuario</Link>
            </b>
          </p>
        </form>

      </div>
      <div className="login_logo">
        <img alt="logo" src={logo} />
        <span>OnPlay</span>
      </div>
      <Loading visible={loading && loading} />
    </div>
  );
};
