import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import bg from "../../assets/img/bg-blue.png";
import logo from "../../assets/img/logoWhite.png";
import Api from "../../common/Api/Api";
import { Loading } from "../../components/Loading/Loading";
import { modalError } from "../../components/SweetAlert/Error";
import { modalSucces } from "../../components/SweetAlert/Success";
export const Request = () => {

  var dataForm;

  const [idCountry, setIdCountry] = useState(0)
  const [idState, setIdState] = useState(0)
  const [loading, setLoading] = useState(false)


  const { data: countries, isLoading: loadContries, error } = useQuery('countries', () =>
    fetch('https://countries.onplay.com.co/countries').then(response =>
      response.json()
    ).catch(error => console.log(error))
  );
  const { data: states, isLoading: loadStates, refetch } = useQuery('states', () =>
    fetch(`https://countries.onplay.com.co/countries/states/${idCountry}`).then(response =>
      response.json()
    ).catch(error => console.log(error))
  )
  const { data: cities, isLoading: loadCities, refetch: refetchCities } = useQuery('cities', () =>
    fetch(`https://countries.onplay.com.co/countries/cities/${idState}`).then(response =>
      response.json()
    ).catch(error => console.log(error))
  )
  const handleState = (e) => {
    setIdCountry(e.target.value)
  }
  const handleCities = (e) => {
    setIdState(e.target.value)
  }
  useEffect(() => {
    refetch()
  }, [idCountry])

  useEffect(() => {
    refetchCities()
  }, [idState])

  useEffect(() => {
    if(loadCities || loadStates || loadContries){
      setLoading(true)
    }else{
      setLoading(false)
    }
  }, [loadCities, loadStates, loadContries])
  
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch
  } = useForm();
  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything

  const mutation = useMutation(data => {

    return Api.post('/request/postsolicitud ', data)
  }, {
    onSuccess: data => {
      if (data?.ok === false) {
        modalError({ message: data?.payload.message ? data?.payload.message : 'Revisa tus datos, por favor' });
      } else {
        modalSucces({ message: "La petición se ha realizado de manera exitosa", url: "/" });
      }
    },
    onError: () => {
      modalError({ message: 'Parece que tenemos problemas' });
    }
  })

  const onSubmit = (data) => {
    const countryName = countries.find(item => item.id == data.Country).name
    const stateName = states.find(item => item.id == data.State).name
    const cityName = cities.find(item => item.id == data.Municipality).name
    dataForm = { 
      ...data,
      Country: countryName,
      State: stateName,
      Municipality: cityName
        };
    mutation.mutate(dataForm)
  };

  const onAmountChange = e => {
    const amount = e.target.value;

    if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
      this.setState(() => ({ amount }));
    }
  };

  return (
    <div className="request">
      <div className="request_logo" style={{ backgroundImage: `url(${bg})` }}>
        <img alt="logo" src={logo} />
        <span>
          OnPlay</span>
        <p>
          ¿Ya tienes una cuenta?
          <b>
            <Link to="/"> Inicia sesion</Link>
          </b>
        </p>
      </div>

      <div className="request_content">
      <Link to="/">
        <AiOutlineArrowLeft className="absolute top-2 left-2 backLog text-3xl text-blue-900" />
        </Link>
        <h1 className="w-full text-center">Solicita un usuario</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-component3 w-11/12 flex flex-col mb-5">
            <p>Nombre de la empresa</p>
            <input type="" className="w-8/10" {...register("NameCompany", { required: true })} />
            {errors.NameCompany ? <span className="m-2 text-red-400">El nombre de la empresa es requerido</span> : <></>}
          </div>
          {countries ? <div className="input-component3 w-11/12 flex flex-col mb-5">
            <p>País</p>
            <select
              {...register("Country", { required: true })}
              onChange={handleState}>
              {countries.map((country, ind) => (
                <option key={ind} value={country.id}>{country.name}</option>
              ))
              }
            </select>
            {errors.Country ? <span className="m-2 text-red-400">El país es requerido</span> : <></>}
          </div> : <></>}
          {states ? <div className="input-component3 w-11/12 flex flex-col mb-5">
            <p>Departamento o estado</p>
            <select
              onInput={handleCities}
              {...register("State", { required: true })}
            >
              {states.map((state, ind) => (
                <option key={ind} value={state.id}>{state.name}</option>
              ))}
            </select>
          </div> : <></>}
          {cities ? <div className="input-component3 w-11/12 flex flex-col mb-5">
            <p>Ciudad</p>
            <select
              {...register("Municipality", { required: true })}
            >
              {cities.map((cities, ind) => (
                <option key={ind} value={cities.id}>{cities.name}</option>
              ))}
            </select>
          </div> : <></>}
          <div className="input-component3 w-11/12 flex flex-col mb-5">
            <p>Nombre del representante</p>
            <input type="" className="w-8/10" {...register("LegalRepresentative", { required: true })} />
            {errors?.LegalRepresentative ? <span className="m-2 text-red-400">El nombre del representate es requerido</span> : <></>}
          </div>
          <div className="input-component3 w-11/12 flex flex-col mb-5">
            <p>Teléfono</p>
            <input type="number" pattern={/[0-9]{4}/} className="w-8/10"  {...register("CompanyPhone", {
              required: true, pattern: /[0-9]{4}/, minLength: 10,
              maxLength: 10, message: 'El número debe tener mínimo 10 digitos'
            })} />
            {errors.CompanyPhone ? <span className="m-2 text-red-400">{error?.CompanyPhone?.message ? error.CompanyPhone.message : 'El número de teléfono es requerido '}</span> : <></>}
          </div>
          <div className="input-component3 w-11/12 flex flex-col mb-5">
            <p>Correo electrónico</p>
            <input placeholder="ej: usuario@email.com" type="text" pattern={/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/} className="w-8/10" {...register("CompanyEmail", { required: true, pattern: {
            value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
            message: 'Correo inválido',
            }, })} />
            {errors.CompanyEmail ? <span className="m-2 text-red-400">{errors?.CompanyEmail?.message ? errors.CompanyEmail.message : 'El correo de la empresa es requerido'}</span> : <></>}
          </div>
          <input type="submit" value="Enviar" className="submit " />
        </form>
      </div>
      <Loading visible={mutation.isLoading} />
      <Loading visible={loading}/>
    </div>
  );
};
