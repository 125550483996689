import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import InputComponent from "../../Inputs/InputComponent3/InputComponent3";
import { useQuery } from "react-query";
import Api from "../../../common/Api/Api.js";


//Modal para ver los datos de la solicitud

export const SeeRequest = ({ visible, setVisible, data1 }) => {

  const [doc1, setDoc1] = useState('')
  const [doc2, setDoc2] = useState('')
  const handleFile = (url) => {
    const windowName = 'Documento'; 
    const windowFeatures = 'width=800,height=800'
    const newWindow = window.open(url, windowName, windowFeatures);
    return newWindow
  }
  const { data } = useQuery(
    "ClientDetail",
    () => Api.get("/user/getDetailUsers/" + data1.IdCompany),
    {
      refetchOnWindowFocus: false,
      enabled: visible,
    }
  );
  useEffect(() => {
    if(data && data?.payload[0]?.FileDocument && data?.payload[0]?.FileDocumentIdentification){
      setDoc1(data?.payload[0].FileDocument)
      setDoc2(data?.payload[0].FileDocumentIdentification)
    }
  }, [data])
  return (

    <Modal
      key={data1 && data1.idRequest}
      title="Solicitud"
      visible={visible}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      onCancel={() => setVisible(!visible)}
    >
      <form className="flex flex-col request-modal items-center pl-4">
        
        <InputComponent
          type="text"
          label="Nombre del solicitante"
          defaultValue={data1 && data1.LegalRepresentative}
          placeholder=""
          icon="fa fa-user-o"
          className="w-auto"
          disabled
        />
        <InputComponent
          type="text"
          label="Nombre de la empresa"
          placeholder=""
          defaultValue={data1 && data1.NameCompany}
          icon="fa fa-user-o"
          className=""
          disabled

        />
        <InputComponent
          type="text"
          label="Número de teléfono"
          placeholder=""
          defaultValue={data1 && data1.CompanyPhone}
          icon="fa fa-user-o"
          className=""
          disabled

        />
        <InputComponent
          type="text"
          label="Ciudad"
          placeholder=""
          defaultValue={data1 && data1.Municipality}
          icon="fa fa-user-o"
          className=""
          disabled

        />
        <InputComponent
          type="text"
          label="Correo"
          placeholder=""
          defaultValue={data1 && data1.CompanyEmail}
          icon="fa fa-user-o"
          className=""
          disabled

        />
        {doc1 && doc2 ? <h1 className="mini-texts  m-0 font-semibold  text-gray-500 self-start">Documentos</h1> : <></>}
        {doc1 ? <div className="input-component3 my-2 btnDoc">
          <button type="" onClick={() => handleFile(doc1)}> Documento Legal de la empresa</button>
        </div> : <></>}
        {doc2 ? <div className="input-component3 my-2 btnDoc">
          <button type="" onClick={() => handleFile(doc2)}> Documento Representante Legal</button>
        </div> : <></>}
      </form>
    </Modal>
  );
};
