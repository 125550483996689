import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import logo from "../../../assets/img/logoWhite.png";
import { Link, useParams } from "react-router-dom";
import bg from "../../../assets/img/bg-blue.png";
import jwt_decode from "jwt-decode";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Loading } from "../../../components/Loading/Loading";
import { UploadFirebase } from "../../../components/firebase/PromiseUpload";
import { modalSucces } from "../../../components/SweetAlert/Success";
import { modalError } from "../../../components/SweetAlert/Error";
import File from "../../../components/Inputs/Files.jsx";
import InputForm from "../../../components/Inputs/inputForm/InputForm.jsx";
import { apiUrl } from "../../../common/Config/Environments";

// const Navigation = (props) => {
//   return (
//     <Row justify="end" className="w-11/12">
//       {props.current !== props.allSteps.length - 2 && (
//         <Col>
//           <Button
//             className="btn_accent_orange"
//             onClick={props.prev}
//             style={{ marginRight: 5 }}
//           >
//             <BsArrowLeft />
//           </Button>
//         </Col>
//       )}
//       {props.current !== props.allSteps.length && (
//         <Col>
//           <Button className="btn_accent_blue" onClick={props.next}>
//             <BsArrowRight />
//           </Button>
//         </Col>
//       )}
//     </Row>
//   );
// };

export const Register = () => {

  const params = useParams();
  const registerData = jwt_decode(params.token);
  // const config = {
  //   navigation: {
  //     component: Navigation, // a React component with special props provided automatically
  //     location: "after", // or before
  //   },
  // };
  const [value, setvalue] = useState("");
  const [value2, setvalue2] = useState("");
  const [loadData, setloadData] = useState(false)

  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    setValue,
  } = useForm();

  useEffect(() => {
    if (registerData) {
      setValue("LegalRepresentative", registerData.data[0]?.LegalRepresentative);
      setValue("CompanyPhone", registerData.data[0].CompanyPhone);
      setValue("Country", registerData.data[0].Country);
      setValue("State", registerData.data[0].State);
      setValue("Municipality", registerData.data[0].Municipality);
      setValue("CompanyEmail", registerData.data[0].CompanyEmail);
    }
  }, [registerData]);
  const onSubmit = (data) => {
    if (value !== "" && value2 !== "") {
      return Promise.all([UploadFirebase({ value: value }), UploadFirebase({ value: value2 })]).then((res) => {
        console.log(res)
        fetch(`${apiUrl}/request/insertComplements`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...data,
            FileDocumentIdentification: res[0],
            FileDocument: res[1],
          }, setloadData(true)),
        }).then((response) => {
          setloadData(false)
          if (response.ok) {
            modalSucces({ message: 'Se ha registrado con éxito', url: "/" })
          } else {
            if (response.json().code == "ER_DUP_ENTRY") {
              modalError({ message: 'El correo ya ha sido registrado' })
            }else{
              modalError({ message: 'Parece que tenemos problemas' })
            }
          }
        });
      }).catch((error)=> console.log("error is", error));
    } else {
      setloadData(false)
      modalError({ message: 'Parece que tenemos problemas' })
    }
  };

const legalRepresentativeData = [
  {
    name: "Nombre",
    register: "LegalRepresentative",
    data: true,
    validate: false
  },
  {
    name: "Identificación",
    register: "Identification",
    data: true,
    validate: false
  },
  {
    name: "Correo electrónico",
    register: "Email",
    data: false,
    validate: false
  },
  {
    name: "Número de teléfono",
    register: "Phone",
    data: false,
    validate: false
  },
  {
    name: "Dirección",
    register: "Address",
    data: false,
    validate: false
  }
]
const companyData = [
  {
    name: "Nombre de la empresa",
    register: "NameCompany",
    data: true,
    validate: false,
  },
  {
    name: "NIT",
    register: "NitCompany",
    data: false,
    validate: {
      required: true, pattern: /[0-9]{4}/, minLength: 7,
      maxLength: 11
    }
  },
  {
    name: "Dirección",
    register: "CompanyAddress",
    data: false,
    validate: false
  },
  {
    name: "Telefono",
    register: "CompanyPhone",
    data: true,
    validate: {
      required: true, pattern: /[0-9]{4}/, minLength: 10,
      maxLength: 10
    }
  }
]
  return (
    <div className="register">
      <div className="register_logo" style={{ backgroundImage: `url(${bg})` }}>
        <img alt="logo" src={logo} />
        <span>
          OnPlay</span>
        <p>
          ¿Ya tienes una cuenta?
          <b>
            <Link to="/"> Inicia sesion</Link>
          </b>
        </p>
      </div>
      <div className="register_content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className="m-6">Datos del representante</h1>
          {legalRepresentativeData.map((item, index)=>{
            if(item.data){
               return <InputForm
               key={index}
               item={item}
               register={register}
               error={errors[`${item.register}`]}
               data={registerData.data[0][`${item.register}`]}
               />
            }else{
              return <InputForm
                key={index}
               item={item}
               data={""}
               error={errors[`${item.register}`]}
               register={register}
               />
            }
          })}
           <div className="w-11/12 py-2">
            <label htmlFor="">Documento de identidad de representante legal</label>
            <File typeData="application/pdf" setvalue={setvalue} value={value} />
          </div>
          <h1 className="m-6">Datos de la empresa</h1>
          {companyData.map((item, index)=>{
            if(item.data){
               return <InputForm
               key={index}
               item={item}
               error={errors[`${item.register}`]}
               register={register}
               data={registerData.data[0][`${item.register}`]}
               />
            }else{
              return <InputForm
                key={index}
                error={errors[`${item.register}`]}
               item={item}
               data={""}
               register={register}
               />
            }})}
          <div className="w-11/12 py-2">
            <label htmlFor="">Documento de representación legal</label>
            <File typeData="application/pdf" setvalue={setvalue2} value={value2} />
          </div>
          <button
            type="submit"
            className="btn_accent_blue submit"
          >Enviar</button>
          {/* <Steps config={config}>
            <Step
              component={() => (
                <Steps1
                  data={registerData.data}
                  getValues={getValues}
                  setvalue={setvalue}
                  value={value}
                  errors={errors}
                  register={register}
                />
              )}
            />
            <Step
              component={() => (
                <Steps2
                  data={registerData.data}
                  getValues={getValues}
                  setvalue={setvalue2}
                  value={value2}
                  errors={errors}
                  register={register}
                />
              )}
            />
          </Steps> */}
        </form>
      </div>
      <Loading visible={loadData} />
    </div>
  );
};
