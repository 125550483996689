import React, { useEffect, useState } from "react";
import { message, Modal } from "antd";
import InputComponent from "../../Inputs/InputComponent3/InputComponent3";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { Loading } from "../../Loading/Loading";
import "./styles.scss";
import { apiUrl } from "../../../common/Config/Environments";

// Modal para aceptar la solictud
const durations = [, "3 meses", "6 meses", "12 meses", "2 años"];
export const SuccessRequest = ({ visible, setVisible, data }) => {
  const [validate, setvalidate] = useState(false)
  const [dataForm, setdataForm] = useState({});
  //Se crean tres estados para gestionar las fechas de duración del contrato
  const [start, setStart] = useState("");
  const [duration, setDuration] = useState("");
  const [end, setEnd] = useState("");
  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
  } = useForm();
  //Esta función maneja el cambio de la primera fecha y teniendo en cuenta la duración del contrato proporciona la fecha final
  const handleDuration = (value) => {
    if (durations.includes(value) || value == "") {
      setDuration(value);
      if (value == "") {
        setEnd("");
      }
    }
  };
  const handleStart = (date) => {
    const validate = (string) => {
      var regex = /^\d$/;
      return regex.test(string);
    };
    setStart(date);
    const time = duration.substring(0, 1);
    const endDate = date.split("-");
    endDate.splice(
      1,
      1,
      `${
        Number(endDate[1]) +
        (time == "2" ? 24 : time == "1" ? 12 : Number(time))
      }`
    );
    if (endDate[1] > 12) {
      endDate.splice(0, 1, `${Number(endDate[0]) + 1}`);
      endDate.splice(1, 1, `${Number(endDate[1]) - 12}`);
    }
    if (endDate[1] > 12) {
      endDate.splice(0, 1, `${Number(endDate[0]) + 1}`);
      endDate.splice(1, 1, `${Number(endDate[1]) - 12}`);
    }
    if (validate(endDate[1])) {
      endDate.splice(1, 1, `0${endDate[1]}`);
    }
    setEnd(endDate.join("-"));
  };

  useEffect(() => {
    if (start !== "" && duration !== "") {
      handleStart(start);
    } else {
      setEnd("");
    }
  }, [duration]);

  // petición para aceptar la solicitud
  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery(
    "register",
    async () =>
      await fetch(`${apiUrl}/request/UpdateNumber/` + data?.CompanyEmail, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(dataForm),
      })
        .then((response) => {
          response.json();
          window.location = "/request"
        })
        .catch((err) => console.log(err, data?.CompanyEmail)),
    {
      enabled: false,
    }
  );

  // Envia los datos a la peticion fetch
  useEffect(() => {
    if (validate) {
      console.log(dataForm);
      refetch();
    }
  }, [dataForm, validate]);

  const onSubmit = (data) => {
    setdataForm({ ...data, EndDate: end, StartDate: start });
    if (
      data.LocalNumber !== "" &&
      data.FieldsNumber !== "" &&
      start !== "" &&
      end !== ""
    ) {
      if (data.LocalNumber > data.FieldsNumber) {
        console.log(data.LocalNumber > data.FieldsNumber);
        message.error(
          "El número de locales no puede ser mayor al número de canchas"
        );
      } else {
        // RegisterFetch();
        setvalidate(true)
      }
    } else {
      message.error("Por favor llena todos los campos");
    }
  };

  return (
    <Modal
      title="Aceptar solicitud"
      visible={visible}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      onCancel={() => setVisible(!visible)}
    >
      <form
        onSubmit={(e) => e.preventDefault()}
        className="flex flex-col request-modal "
      >
        <div className="input-component3 flex flex-col">
          <p>Número de locales</p>
          <input
            type="number"
            pattern="[0-9]+"
            {...register("LocalNumber", { required: true })}
          />
          {errors.LocalNumber ? (
            <span>Número de locales requerido</span>
          ) : (
            <></>
          )}
        </div>
        <div className="input-component3 flex flex-col">
          <p>Número de canchas</p>
          <input
            type="number"
            pattern="[0-9]+"
            {...register("FieldsNumber", { required: true })}
          />
        </div>
        {/* Input duración de contrato */}
        <div className="input-component3 w-full flex flex-col">
          <p>Duración de contrato</p>
          <div className="datestart">
            <input
              list="duration"
              className="w-11/12 "
              value={duration}
              onClick={() => handleDuration("")}
              onInput={(e) => handleDuration(e.target.value)}
              required="La duración del contrato es requerida"
            />
            <datalist id="duration" className="">
              {durations.map((item, ind) => (
                <option key={ind} value={item} />
              ))}
            </datalist>
          </div>
        </div>

        <div className="input-component3 flex flex-col ">
          <p>Fecha de inicio</p>
          <div className="datestart">
            <input
              className="w-11/12 "
              type="date"
              name="StartDate"
              value={start}
              disabled={duration === ""}
              error={errors.StartDate}
              onInput={(e) => handleStart(e.target.value)}
              {...register("StartDate", { required: true })}
            />
            <p className="datestart_date">{start}</p>
          </div>
        </div>
        <div className="input-component3 w-full flex flex-col">
          <p>Fecha de fin</p>
          <div className="dateend">
            <input
              className="w-11/12"
              type="date"
              name="EndDate"
              value={end}
              disabled={duration === ""}
              error={errors.EndDate}
              {...register("EndDate", { required: true })}
            />
            <p className="dateend_date">{end}</p>
          </div>
        </div>
        <button
          type="submit"
          onClick={() => onSubmit(getValues())}
          className="my-2 btn-close p-2"
        >
          Aceptar
        </button>
      </form>
      <Loading visible={isLoading} />
    </Modal>
  );
};
