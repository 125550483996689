import React, { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { Layout, Popover } from "antd";
import { auth } from "../../../services/Auth/AuthActions";
import { MdNotificationsNone } from 'react-icons/md'
import jwt_decode from "jwt-decode";
import { Token } from "../../../common/Storage/Token";
import { useQuery } from 'react-query';
import Api from '../../../common/Api/Api.js';
import { apiUrl } from '../../../common/Config/Environments';

const { Header } = Layout;

// Componente de cabecera del dashboard

export const HeaderC = () => {
	const userData = jwt_decode(Token.getToken());
	const dispatch = useDispatch();

	// Contenido de la etiqueta Popover

	const content = (
		<div className="cursor-pointer">
			<p onClick={() => dispatch(auth.logout())} >Cerrar sesion</p>
		</div>
	);

	  const { data: dataLocal, isLoading } = useQuery(
		'locals',
		() =>
		  fetch(
			`${apiUrl}/Local/getLocal/` + userData.data.IdCompany,
			{
			  method: 'GET',
			}
		  )
			.then(async (response) => {
			  return await response.json()
			})
			.catch((err) => {
			  console.log(err)
			}),
		{
		  refetchOnWindowFocus: false,
		  enabled: true,
		}
	  )
	const validateUser = (data) => {
		if (data.IdRole == 1) {
			return 'Administrador'
		} else if (data.IdRole == 2) {
			return data.NameCompany
		} else {
			let name = ''
			if(Array.isArray(dataLocal)){
				dataLocal.forEach(element => {
					if(element.IdLocal == data.IdLocal){
						name = element.NameLocal
					}
				})
			}
			return name
		}
	}
	return (
		<Header className="header" >
			<div className="header_title">
				<span>Bienvenido,  <b>{validateUser(userData?.data)}</b> </span>
			</div>
			<div className="header_options">
				<div className="notification">
					<MdNotificationsNone />
					<div className="dot" ></div>
				</div>
				<Popover className="cursor-pointer" placement="top" content={content} trigger="click">
					<img alt="profile" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" />
				</Popover>
			</div>
		</Header>

	)
}
