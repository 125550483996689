export const en = {
  "test": "Welcome to React and react-i18next",
  "sing": "Sing up",
  "login": "Login",
  "name_first": "first name",
  "name_last": "last name",
  "mail": "Email",
  "phone": "Phone",
  "sex": "Sex",
  "status_marital": "Status Marital",
  "birthdate": "Birthdate",
  "place_birth": "Place of birth",
  "passport": "Passport",
  "passport_number": "Passport number",
  "password": "Password",
  "confirm_password": "Confirm password",
  "terms": "I accept the privacy policies, terms and conditions of the service.",
  "register": "To register",
  "pleace": "Please input your",
  "pleace_select": "Please select your",
  "pleace_confirm": "Pleace",
  "inconsistent_password": "Two passwords that you enter is inconsistent!",
  "accept_privacy": "accept privacy policies",
  "minimum": "Minimum you must enter 3 characters!",
  "maximum": "Maximum you can enter 60 characters!",
  "not_age": "You are not of age!",
  "single": "Single",
  "married": "Married",
  "reset_password": "Reset password",
  "continue": "Continue",
  "remember": "Remember"
}